// Generated by Framer (e4277c8)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["xpULIiMit", "o8cc3Iu8X", "uweXUtZCH", "LUMM7Im8k", "zfNzlYOeA", "wXZFzHYnp", "qDJrDZMj7", "v1g_VDQTp", "Og5oY15eT", "KdTmp_WmP", "UgLUo_cbR", "YK_i45cb_", "p2mFc9jRi", "sKzQCAoPd"];

const serializationHash = "framer-BxX4V"

const variantClassNames = {KdTmp_WmP: "framer-v-axq1ba", LUMM7Im8k: "framer-v-18f8lbq", o8cc3Iu8X: "framer-v-1yo8602", Og5oY15eT: "framer-v-1lz9gev", p2mFc9jRi: "framer-v-wud650", qDJrDZMj7: "framer-v-uklc4k", sKzQCAoPd: "framer-v-iqoxoy", UgLUo_cbR: "framer-v-1rrfo3d", uweXUtZCH: "framer-v-11hy793", v1g_VDQTp: "framer-v-1ehv1b9", wXZFzHYnp: "framer-v-phmm1v", xpULIiMit: "framer-v-zqldk6", YK_i45cb_: "framer-v-17s2z7r", zfNzlYOeA: "framer-v-gdsg13"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"$spacing-10": "xpULIiMit", "$spacing-100": "uweXUtZCH", "$spacing-15": "p2mFc9jRi", "$spacing-20": "YK_i45cb_", "$spacing-200": "o8cc3Iu8X", "$spacing-25": "UgLUo_cbR", "$spacing-30": "KdTmp_WmP", "$spacing-40": "Og5oY15eT", "$spacing-5": "sKzQCAoPd", "$spacing-50": "v1g_VDQTp", "$spacing-60": "qDJrDZMj7", "$spacing-70": "wXZFzHYnp", "$spacing-80": "zfNzlYOeA", "$spacing-90": "LUMM7Im8k"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "xpULIiMit"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "xpULIiMit", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-zqldk6", className, classNames)} data-framer-name={"$spacing-10"} layoutDependency={layoutDependency} layoutId={"xpULIiMit"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({KdTmp_WmP: {"data-framer-name": "$spacing-30"}, LUMM7Im8k: {"data-framer-name": "$spacing-90"}, o8cc3Iu8X: {"data-framer-name": "$spacing-200"}, Og5oY15eT: {"data-framer-name": "$spacing-40"}, p2mFc9jRi: {"data-framer-name": "$spacing-15"}, qDJrDZMj7: {"data-framer-name": "$spacing-60"}, sKzQCAoPd: {"data-framer-name": "$spacing-5"}, UgLUo_cbR: {"data-framer-name": "$spacing-25"}, uweXUtZCH: {"data-framer-name": "$spacing-100"}, v1g_VDQTp: {"data-framer-name": "$spacing-50"}, wXZFzHYnp: {"data-framer-name": "$spacing-70"}, YK_i45cb_: {"data-framer-name": "$spacing-20"}, zfNzlYOeA: {"data-framer-name": "$spacing-80"}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-BxX4V.framer-vh6xl8, .framer-BxX4V .framer-vh6xl8 { display: block; }", ".framer-BxX4V.framer-zqldk6 { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 0px; height: 10px; justify-content: flex-start; overflow: hidden; padding: 0px; position: relative; width: 10px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-BxX4V.framer-zqldk6 { gap: 0px; } .framer-BxX4V.framer-zqldk6 > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-BxX4V.framer-zqldk6 > :first-child { margin-top: 0px; } .framer-BxX4V.framer-zqldk6 > :last-child { margin-bottom: 0px; } }", ".framer-BxX4V.framer-v-1yo8602.framer-zqldk6 { height: 200px; width: 200px; }", ".framer-BxX4V.framer-v-11hy793.framer-zqldk6 { height: 100px; width: 100px; }", ".framer-BxX4V.framer-v-18f8lbq.framer-zqldk6 { height: 90px; width: 90px; }", ".framer-BxX4V.framer-v-gdsg13.framer-zqldk6 { height: 80px; width: 80px; }", ".framer-BxX4V.framer-v-phmm1v.framer-zqldk6 { height: 70px; width: 70px; }", ".framer-BxX4V.framer-v-uklc4k.framer-zqldk6 { height: 60px; width: 60px; }", ".framer-BxX4V.framer-v-1ehv1b9.framer-zqldk6 { height: 50px; width: 50px; }", ".framer-BxX4V.framer-v-1lz9gev.framer-zqldk6 { height: 40px; width: 40px; }", ".framer-BxX4V.framer-v-axq1ba.framer-zqldk6 { height: 30px; width: 30px; }", ".framer-BxX4V.framer-v-1rrfo3d.framer-zqldk6 { height: 25px; width: 25px; }", ".framer-BxX4V.framer-v-17s2z7r.framer-zqldk6 { height: 20px; width: 20px; }", ".framer-BxX4V.framer-v-wud650.framer-zqldk6 { height: 15px; width: 15px; }", ".framer-BxX4V.framer-v-iqoxoy.framer-zqldk6 { height: 5px; width: 5px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 10
 * @framerIntrinsicWidth 10
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"o8cc3Iu8X":{"layout":["fixed","fixed"]},"uweXUtZCH":{"layout":["fixed","fixed"]},"LUMM7Im8k":{"layout":["fixed","fixed"]},"zfNzlYOeA":{"layout":["fixed","fixed"]},"wXZFzHYnp":{"layout":["fixed","fixed"]},"qDJrDZMj7":{"layout":["fixed","fixed"]},"v1g_VDQTp":{"layout":["fixed","fixed"]},"Og5oY15eT":{"layout":["fixed","fixed"]},"KdTmp_WmP":{"layout":["fixed","fixed"]},"UgLUo_cbR":{"layout":["fixed","fixed"]},"YK_i45cb_":{"layout":["fixed","fixed"]},"p2mFc9jRi":{"layout":["fixed","fixed"]},"sKzQCAoPd":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerMxkg9RHsR: React.ComponentType<Props> = withCSS(Component, css, "framer-BxX4V") as typeof Component;
export default FramerMxkg9RHsR;

FramerMxkg9RHsR.displayName = "Spacing";

FramerMxkg9RHsR.defaultProps = {height: 10, width: 10};

addPropertyControls(FramerMxkg9RHsR, {variant: {options: ["xpULIiMit", "o8cc3Iu8X", "uweXUtZCH", "LUMM7Im8k", "zfNzlYOeA", "wXZFzHYnp", "qDJrDZMj7", "v1g_VDQTp", "Og5oY15eT", "KdTmp_WmP", "UgLUo_cbR", "YK_i45cb_", "p2mFc9jRi", "sKzQCAoPd"], optionTitles: ["$spacing-10", "$spacing-200", "$spacing-100", "$spacing-90", "$spacing-80", "$spacing-70", "$spacing-60", "$spacing-50", "$spacing-40", "$spacing-30", "$spacing-25", "$spacing-20", "$spacing-15", "$spacing-5"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerMxkg9RHsR, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})